import { DOCUMENT } from '@angular/common';
import {
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { RESOURCES } from '../../constants/resource-service.constants';
import { APIResponse } from '../../interfaces/api.interface';
import { UserData } from '../../models/user-data';
import { UserDataLocalStorage } from '../../reducer/user-data/user-data.actions';
import { UserDataFull } from '../../reducer/user-data/user-data.selector';
import { DialogService } from '../../services/dialog.service';
import { UisrAuthService } from '../../services/uisr-auth.service';
import { WebSocketService } from '../../services/v2-socket.io.service';
import { SidebarComponent } from '../sidebar/sidebar.component';

@UntilDestroy()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  readonly dialogService = inject(DialogService);

  @ViewChild(SidebarComponent, { static: true })
  sidebarComponent!: SidebarComponent;
  @ViewChild('trialCallToAction') trialCallToAction!: TemplateRef<any>;

  lastScrollTop: any = 0;
  resources = RESOURCES;
  loadingSubscription = new BehaviorSubject(false);
  journeySteps: any[] = []
  incompleteSteps: any = []
  userSubscription:any = []
  differenceInDays:number = 0
  showParagraph = true;
  userData = {} as UserData;

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private authService: UisrAuthService,
    private apiService: UisrApiServiceV2,
    public socketService: WebSocketService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new UserDataLocalStorage());

    this.store
      .pipe(
        select(UserDataFull),
        distinctUntilChanged(isEqual),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.userData = data;
      });

    this.authService.startValidationLoop();
    this.authService.updatePermissions();
    this.authService.updateAssistantSettings();
    this.authService.updateFeatureConfig();
    this.authService.updateFeatureAccess();
    this.renderer.addClass(this.document.body, 'inner-app');

  }
  
  ngAfterViewInit(): void {
    this._getSubscription();
  }

  openSidebar() {
    this.sidebarComponent.openOrCloseSidebar();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'inner-app');
  }

  private _getSubscription() {
    this.apiService
      .get(RESOURCES.lastSubscriptionByUser)
      .pipe(loadingState(this.loadingSubscription), untilDestroyed(this))
      .subscribe((res: APIResponse<any>) => {
        this.userSubscription = res.data;

        if (this.userSubscription.subscriptionExpire) {
          const currentDate = new Date(); // Fecha actual
          const expirationDate = new Date(
            this.userSubscription.subscriptionExpire
          ); // Fecha de expiración

          // Calcula la diferencia en milisegundos
          const differenceInMs =
            expirationDate.getTime() - currentDate.getTime();

          // Convertir la diferencia de milisegundos a días (1 día = 86,400,000 ms)
          this.differenceInDays = Math.floor(
            differenceInMs / (1000 * 60 * 60 * 24)
          );
        }
      });
  }

  hideParagraph() {
    this.showParagraph = false;
    localStorage.setItem('showParagraph', JSON.stringify(this.showParagraph));
  }
}
