import { inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  finalize,
  first,
  firstValueFrom,
  ReplaySubject,
} from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { SubscriptionComplete } from '../interfaces/subscription-complete.interface';
import { UisrApiServiceV2 } from './uisr-api.service-v2';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private gettingSubscription: ReplaySubject<void> | null = null;
  subscription$ = new BehaviorSubject<SubscriptionComplete | null>(null);

  private readonly _apiService = inject(UisrApiServiceV2);

  getSubscription() {
    if (!this.gettingSubscription) {
      this.gettingSubscription = new ReplaySubject<void>(1);

      this._apiService
        .get(RESOURCES.lastSubscriptionByUser)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (res: APIResponse<SubscriptionComplete>) => {
            this.subscription$.next(res.data);
            this._completeGettingSubscription();
          },
          error: () => {
            this._completeGettingSubscription();
          },
        });
    }
    return firstValueFrom(this.gettingSubscription.pipe(first()));
  }

  private _completeGettingSubscription() {
    this.gettingSubscription?.next();
    this.gettingSubscription?.complete();
    this.gettingSubscription = null;
  }
}
