<div class="grid min-h-[100svh] max-h-[100svh] overflow-hidden bg-slate-100 text-slate-600 w-svw flex-1 grid-rows-[1fr]">
    <div class="flex w-[100svw] min-h-0">
        <app-sidebar></app-sidebar>
        <div class="relative flex flex-col grow overflow-hidden transition-colors duration-200">
            <app-header (openSidebarRequest)="openSidebar()"></app-header>
            <div class="relative overflow-y-auto overflow-x-hidden flex flex-col grow" cdkScrollable>
                <router-outlet></router-outlet>
            </div>
            <app-assistant-chat></app-assistant-chat>
        </div>
    </div>

</div>

<ng-template #trialCallToAction>
    <div class="flex max-w-3xl">
        <div
            class="bg-indigo-100 border-r border-slate-200 shadow-sm max-md:hidden relative basis-2/4 grid grid-cols-2 overflow-hidden z-[1]">
            <img src="/assets/images/shape-8.webp" alt="Decoration" aria-hidden="true"
                class="absolute h-[500px] -top-36 -z-10">
            <div>
                <img src="https://mi-despacho-prod.nyc3.cdn.digitaloceanspaces.com/assets/clients-icon.svg"
                    alt="Añade tu primer cliente" class="size-48 z-10">
            </div>
            <div>
                <img src="https://mi-despacho-prod.nyc3.cdn.digitaloceanspaces.com/assets/dossier-icon.svg" alt=""
                    class="size-48 z-10 mt-8">
            </div>
            <div>
                <img src="https://mi-despacho-prod.nyc3.cdn.digitaloceanspaces.com/assets/colab-icon.svg" alt=""
                    class="size-48 z-10 -mt-8">
            </div>
            <div>
                <img src="https://mi-despacho-prod.nyc3.cdn.digitaloceanspaces.com/assets/client-portal-icon.svg" alt=""
                    class="size-48 z-10">
            </div>
        </div>
        <div class="relative p-4 lg:p-8 flex flex-col justify-between basis-2/4">
            <h2 class="text-2xl font-semibold text-gray-800 mb-4 text-pretty">¡Continúa disfrutando de <span
                    class="app-name">MiDespacho</span> sin
                interrupciones!
            </h2>
            <p class="text-gray-600 mb-4">
                Estás a <strong class="text-indigo-600">{{ differenceInDays }} días</strong> de desbloquear todo el potencial de MiDespacho.
                Añade tu método de pago ahora y disfruta de una experiencia fluida y sin límites.
            </p>
            <p class="text-gray-500 mb-6">
                No te preocupes, no se te cobrará hasta que termine tu prueba gratuita.
            </p>
            <div class="flex justify-center space-x-4">
                <a [routerLink]="'/settings/plans'" class="uisr-primary-button"
                    (click)="dialogService.closeAll()">Añadir método de pago</a>
                <button class="uisr-secondary-button" mat-dialog-close>Cerrar</button>
            </div>
        </div>

    </div>

</ng-template>